<template>
  <div id="people-view">
    <div class="spot-key">
      <span>{{ $route.params.key }}</span>
    </div>
    <div class="top-row">
      <div class="map-list">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
        <button class="btn-people-list" @click="goPeopleList">
          <span>在場人員列表</span>
        </button>
      </div>
      <div class="total-card first">
        <div class="header" id="in">進場人次</div>
        <div class="count">{{ convertNum(peopleStatus.entranceToday) }}</div>
      </div>
      <div class="total-card second">
        <div class="header" id="out">出場人次</div>
        <div class="count">{{ convertNum(peopleStatus.BACK_DOOR) }}</div>
      </div>
      <div class="total-card third">
        <div class="header" id="now">在場人次</div>
        <div class="count">{{ convertNum(peopleStatus.onSite) }}</div>
      </div>
    </div>
    <div class="content-row" v-if="!showPhoto">
      <div class="content-card" v-for="item in groupDataByPage[nowPage]">
        <div class="name">{{ item.group }}</div>
        <div class="count">{{ convertNum(item.onSite) }}</div>
      </div>
    </div>
    <div v-else class="loading-block">
      <div v-if="!hasOshcard">
        <span class="nick-name">{{ nickName }}</span>
        <img src="~@/assets/oshcard.png" class="oshcard-png" />
        <img :src="imgUrl" class="user-face" />
      </div>
      <img v-else :src="imgUrl" class="oshcard-png" />
      <span>職安卡</span>
      <img v-if="!hasOshcard" src="~@/assets/cross.png" class="btn-circle" />
      <img v-else src="~@/assets/check.png" class="btn-circle" />
    </div>
    <div class="page-row">
      <button class="page-prev" @click="turnPage(nowPage - 1)">
        <img src="../assets/back.png" />
      </button>
      <button
        class="page-index"
        v-for="index in groupDataByPage.length"
        :class="{ active: nowPage + 1 === index }"
        @click="turnPage(index - 1)"
      >
        <span> {{ index }}</span>
      </button>
      <button class="page-next" @click="turnPage(nowPage + 1)">
        <img src="../assets/back.png" />
      </button>
    </div>
    <!-- <button @click="test()">test</button> -->
    <div class="logo-group">
      <img src="~@/assets/futsu-logo.png" id="futsu-logo" />
      <img src="~@/assets/lin-logo.png" id="lin-logo" />
      <img src="~@/assets/cec-logo.png" id="cec-logo" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getPeopleCount, test } from '@/api/index.js'
import { mixinWebsocket } from '@/utils/ws-people'
export default {
  name: 'People',
  mixins: [mixinWebsocket],
  data() {
    return {
      dotUrl: '',
      peopleStatus: {},
      page: { limit: 8, index: 1 },
      totalPage: 0,
      companyInfo: {},
      timestamp: '',
      employeeNextStatusData: [],
      groupData: [],
      groupDataByPage: [],
      peopleStatusSplited: {},
      showPhoto: false,
      imgUrl: '',
      hasOshcard: false,
      nickName: '',
      nowPage: 0,
    }
  },
  async created() {
    this.dotUrl = require('../assets/' + this.$route.params.key + '-dot.png')
    this.initWebsocket() // 開啓前後端的websocket通道
    this.getPeopleCount()
    setInterval(() => {
      this.getPeopleCount()
    }, 60000)
    setInterval(() => {
      if (this.nowPage === 2) {
        this.turnPage(0)
      } else {
        this.turnPage(this.nowPage + 1)
      }
    }, 3000)
  },
  computed: {
    ...mapState(['isLogin', 'wsPeople', 'stationInfoList']),
    deviceGroup() {
      if (this.$route.params.key === 'g07') {
        return '1'
      } else if (this.$route.params.key === 'g10') {
        return '2'
      } else if (this.$route.params.key === 'g12') {
        return '3'
      } else {
        return '4'
      }
    },
  },
  methods: {
    turnPage(page) {
      if (page < 0) return
      if (page >= this.groupDataByPage.length) return
      this.nowPage = page
    },
    fetchImage(imgUrl) {
      fetch(imgUrl)
        .then((response) => {
          // Check if the response is a redirect (status code 3xx)
          if (response.redirected) {
            // Get the final redirected URL
            const redirectedUrl = response.url

            // Set the final URL as the src for the img element
            this.imgUrl = redirectedUrl
            console.log('redirectedUrl', this.imgUrl)
          } else {
            // If not redirected, set the original URL as the src
            this.imgUrl = imgUrl
            console.log('imgUrl', this.imgUrl)
          }
        })
        .catch((error) => {
          console.error('Error fetching image:', error)
        })
    },
    convertNum(num) {
      if (!num) {
        return '00'
      } else if (num < 10) {
        return '0' + num.toString()
      } else {
        return num.toString()
      }
    },
    test() {
      const body = {
        sn: 'RLM-00032111',
        Count: 1,
        logs: [
          {
            user_id: 'eff9744d-fd3f-49a1-9108-de66df5bfcf0',
            recog_time: '2023-01-11 13:12:11',
            recog_type: 'face',
            photo:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=',
            body_temperature: '36.5',
            confidence: '95.5',
            reflectivity: 86,
            room_temperature: 25.5,
          },
        ],
      }
      test(body)
    },
    getPeopleCount() {
      getPeopleCount(this.deviceGroup)
        .then((res) => {
          this.peopleStatus = res.data.peopleStatus
          this.peopleStatus.total =
            this.peopleStatus.FRONT_DOOR + this.peopleStatus.BACK_DOOR
          this.groupData = res.data.companyList
          this.totalPage = Math.ceil(this.groupData.length / this.page.limit)

          const innerWidth = window.innerWidth
          if (innerWidth < 768) {
            this.groupDataByPage = this.splitArrayIntoChunksOfLen(
              this.groupData,
              8
            )
          } else {
            this.groupDataByPage = this.splitArrayIntoChunksOfLen(
              this.groupData,
              12
            )
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    splitArrayIntoChunksOfLen(arr, len) {
      const chunks = []
      let i = 0
      const n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
      }
      return chunks
    },
    goCctvList() {
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goPeopleList() {
      let device_group = ''
      switch (this.$route.params.key) {
        case 'g07':
          device_group = '1'
          break
        case 'g10':
          device_group = '2'
          break
        case 'g12':
          device_group = '3'
          break
        case 'nt':
          device_group = '4'
          break
      }
      window.open(
        'https://tycg-face-company-people.skysharp.com.tw/#/?device_group=' +
          device_group,
        '_blank'
      )
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
  watch: {
    wsPeople() {
      this.getPeopleCount()
      this.showPhoto = true
      const data = JSON.parse(this.wsPeople.data)
      const user = Object.assign(data.user)
      // 無職安卡
      if (user.oshcard === null) {
        this.imgUrl = 'https://tycg-backend.skysharp.com.tw' + user.face_image
        this.nickName = user.name
      } else {
        this.hasOshcard = true
        this.fetchImage('https://tycg-backend.skysharp.com.tw' + user.oshcard)
      }
      setTimeout(() => {
        console.log('time out')
        this.showPhoto = false
      }, 5000)
    },
  },
  destroy() {
    this.websocketclose() // 關閉websocket通道
  },
}
</script>

<style></style>
